import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/types';
import { fetchCollection } from 'redux/crud/action';
import { Container, Button } from 'reactstrap';
import styles from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Localization } from 'constant';

SwiperCore.use([Autoplay]);

const NotificationSwiper = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((st: State) => st);
  const { crudState } = useSelector((state: any) => state);

  const [notifications, setNotifications] = useState(
    crudState?.notifications?.collections?.otherInfo?.notifications || [],
  );

  useEffect(() => {
    // fetch non-sensitive notifications if user is not logged in
    if (!auth?.user?.id) {
      dispatch(
        fetchCollection('notifications', 'cms/non-sensitive-notifications', {
          filter: {
            client: Localization.clientName,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    // fetch sensitive notifications when user is logged in
    if (auth?.user?.id) {
      dispatch(
        fetchCollection('notifications', 'cms/sensitive-notifications', {
          filter: {
            client: Localization.clientName,
            role: auth.user.role,
          },
        }),
      );
    }
  }, [auth?.user.id]);

  useEffect(() => {
    if (crudState?.notifications?.collections?.otherInfo?.notifications) {
      setNotifications(crudState?.notifications?.collections?.otherInfo?.notifications || []);
    }
  }, [crudState?.notifications?.collections?.otherInfo?.notifications]);

  function handleDelete(notificationToDelete) {
    const updatedNotifications = notifications.filter((notification) => {
      return notification != notificationToDelete;
    });

    setNotifications(updatedNotifications);
    // dispatch(updateNotification('notifications', { notifications: updatedNotifications }));
  }

  const Div =
    notifications.length > 0
      ? styles.div`
        padding: 15px;
        top: 0px;
        position: absolute;
        width: 100%;
        height: auto;
        text-align: center;
        background: #7864c4;
        color: white;
        z-index: 10001;
        `
      : styles.div``;

  const ButtonMenu = styles(Button)`
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: -6px;
    &:hover {
      background-color: transparent;
    }`;

  return (
    <Div>
      <Container fluid={true}>
        <div>
          <Swiper spaceBetween={50} slidesPerView={1} autoplay={{ delay: 7000 }} loop>
            {notifications.length > 0 &&
              notifications.map((notification, index) => (
                <SwiperSlide key={notification.id}>
                  <div style={{ position: 'absolute', left: 0 }}>
                    {index + 1}/{notifications.length}
                  </div>
                  <div style={{ marginLeft: '50px', marginRight: '50px' }}>{notification.message}</div>
                  <ButtonMenu onClick={() => handleDelete(notification)}>
                    <CloseIcon />
                  </ButtonMenu>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </Container>
    </Div>
  );
};

export default NotificationSwiper;
