import React, { ReactElement } from 'react';
import Loader from './Loader';

export interface BoundaryProps {
  loading: boolean;
  children: ReactElement;
}
const Boundary = ({ loading, children }: BoundaryProps) => {
  if (loading) return <Loader />;
  return children;
};

export default Boundary;
