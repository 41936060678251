import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import { BORROWER_ROLE, CASHIER_ROLE, DEALER_ROLE, SUPERUSER_ROLE, Localization } from 'constant';
import { userLoad, userLogout } from 'redux/auth/actions';
import authProvider from 'utils/authProvider';
import styles from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { clearActionStatus, fetchCollection } from 'redux/crud/action';
import useScreenSize from 'utils/screenSize';
import { isSideBarMenuUsers } from 'helpers';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Typography } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import i18next from 'i18next';
import PDFViewer from 'pdf-viewer-reactjs';
import { PdfNavBar } from 'components/elements';
import { TvsLogout, TvsDownload } from 'components/icons';

const Header = ({ onClickMenu }: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const open = Boolean(anchorEl);

  const handleManual = () => {
    setShowModal(true);
  };

  const handleLogout = () => {
    authProvider.logout();
    dispatch(clearActionStatus('userTc'));

    // clear user info and reset notifications
    dispatch(userLogout());
    dispatch(
      fetchCollection('notifications', 'cms/non-sensitive-notifications', {
        filter: {
          client: Localization.clientName,
        },
      }),
    );
    history.push('/login');
  };

  const languages = [
    {
      code: 'bn',
      name: 'বাংলা (Bangla)',
    },
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'es',
      name: 'Español (Spanish)',
    },
  ];

  const getFullLanguageName = (lng: string) => {
    switch (lng) {
      case 'en':
        return 'English';
      case 'ne':
        return 'Nepali';
      case 'bn':
        return 'বাংলা (Bangla)';
      case 'es':
        return 'Español (Spanish)';
      default:
        return 'English';
    }
  };

  useEffect(() => {
    setLanguage(getFullLanguageName(i18n.language));
  }, [i18n.language]);

  // const getPDF = async () => {
  //   const resp = await fetch(
  //     'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/Harita_CS_UserManual.pdf',
  //     {
  //       method: 'GET',
  //     },
  //   );
  //   const blob = await resp.blob();
  //   const url = URL.createObjectURL(blob);
  //   setManualUrl(url);
  // };

  useEffect(() => {
    // getPDF();
    if (auth?.token) {
      setAnchorEl(null);
      dispatch(userLoad());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!auth.token) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const route = useRouteMatch();
  const screen = useScreenSize();

  const handleEnglish = () => {
    changeLanguage('en');
    setAnchorEl(null);
  };

  const handleNepali = () => {
    changeLanguage('ne');
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const manualToggle = () => setShowModal(!showModal);

  return (
    <Div>
      <Container fluid={isSideBarMenuUsers(auth?.user?.role)}>
        <Modal isOpen={showModal} toggle={manualToggle} size="xl" centered>
          <ModalBody className="mx-3 my-3">
            <div style={{ height: '100%' }} className="d-flex justify-content-between align-items-center">
              <PDFViewer
                document={{
                  url:
                    auth?.user?.role === CASHIER_ROLE ? Localization.cashierManualLink : Localization.managerManualLink,
                }}
                hideRotation={true}
                hideZoom={true}
                navbarOnTop={true}
                navigation={PdfNavBar}
                canvasCss="d-flex justify-content-between align-items-center"
              />
            </div>
          </ModalBody>
        </Modal>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-center">
            {isSideBarMenuUsers(auth?.user?.role) && ['m', 's', 'xs'].includes(screen.screen) && (
              <ButtonMenu onClick={onClickMenu} />
            )}
            {(auth?.user?.role === DEALER_ROLE || auth?.user?.role === SUPERUSER_ROLE) && (
              <Link to="/">
                <img
                  src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
                  alt={Localization.clientName}
                  height="45"
                />
              </Link>
            )}
          </div>
          {auth?.user?.role === BORROWER_ROLE && (
            <Nav>
              <NavItem className={route?.path === '/' ? 'active' : ''}>
                <LinkStyle to="/">Dashboard</LinkStyle>
              </NavItem>
              <NavItem className={route?.path.indexOf('daily-collections') > -1 ? 'active' : ''}>
                <LinkStyle to="/daily-collections">Daily Collections</LinkStyle>
              </NavItem>
              {auth?.user?.role === 'C' && (
                <NavItem className={route?.path.indexOf('branches') > -1 ? 'active' : ''}>
                  <LinkStyle to="/branches">{Localization.branch}</LinkStyle>
                </NavItem>
              )}
              <NavItem className={route?.path.indexOf('specialist') > -1 ? 'active' : ''}>
                <LinkStyle to="/specialist">{Localization.collectionSpecialist}</LinkStyle>
              </NavItem>
              <NavItem className={route?.path.indexOf('cashier') > -1 ? 'active' : ''}>
                <LinkStyle to="/cashiers">Cashier</LinkStyle>
              </NavItem>
              <NavItem className={route?.path.indexOf('account') > -1 ? 'active' : ''}>
                <LinkStyle to="/accounts">Accounts</LinkStyle>
              </NavItem>
            </Nav>
          )}
          <div className="d-flex justify-content-center">
            {auth?.user?.role === DEALER_ROLE && (
              <>
                <IconButton
                  color="inherit"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <LanguageIcon sx={{ display: { sxs: 'none', lg: 'block' }, marginRight: '3px', color: 'white' }} />
                  <div>
                    <Typography component="h6" variant="body1" style={{ color: 'white' }}>
                      {language}
                    </Typography>
                  </div>
                  <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                </IconButton>
                <MenuStyle
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClick={handleClose}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItemStyle
                    style={{
                      color: 'white',
                      display: 'flex',
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      justifyContent: 'start',
                    }}
                    divider={true}
                    value="en"
                    onClick={handleEnglish}
                  >
                    English
                  </MenuItemStyle>
                  <MenuItemStyle
                    style={{
                      color: 'white',
                      display: 'flex',
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      justifyContent: 'start',
                    }}
                    value="bn"
                    onClick={handleNepali}
                  >
                    नेपाली (Nepali)
                  </MenuItemStyle>
                </MenuStyle>
              </>
            )}
            {auth?.user?.role !== DEALER_ROLE && (
              <>
                {Localization.showTranslation && (
                  <IconButton
                    color="inherit"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <LanguageIcon sx={{ display: { sxs: 'none', lg: 'block' }, marginRight: '0px', color: 'white' }} />
                  </IconButton>
                )}
                <MenuStyle
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClick={handleClose}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {languages.map(({ code, name }) => (
                    <MenuItemStyle
                      key={code}
                      value={code}
                      style={{
                        color: 'black',
                        display: 'flex',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        justifyContent: 'start',
                      }}
                      onClick={() => {
                        i18next.changeLanguage(code);
                      }}
                    >
                      {name}
                    </MenuItemStyle>
                  ))}
                </MenuStyle>
              </>
            )}
            {auth?.user?.role !== DEALER_ROLE && (
              <>
                <div className="pt-1">
                  <DropdownStyle isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggleType>
                      <div className="d-flex align-items-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/user_profile.svg`}
                          alt="avatar"
                          style={{ marginRight: '10px' }}
                        />
                        {auth?.user?.first_name} {auth?.user?.last_name}
                        <div className={!dropdownOpen ? 'pb-1' : ''}>
                          {!dropdownOpen ? (
                            <div className="ml-2 header-arrow-down" />
                          ) : (
                            <div className="ml-2 header-arrow-up" />
                          )}
                        </div>
                      </div>
                    </DropdownToggleType>
                    <DropdownMenuStyle right container="body">
                      {Localization.showManuals && (
                        <DropdownItem onClick={handleManual}>
                          <TvsDownload size={28} />
                          {t('User Manual')}
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={handleLogout}>
                        <TvsLogout size={28} />
                        {t('Logout')}
                      </DropdownItem>
                    </DropdownMenuStyle>
                  </DropdownStyle>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </Div>
  );
};

const MenuStyle = styled(Menu)`
  & .MuiMenu-list {
    // background: rgba(42, 42, 62, 1);
    background: white;
  }
`;

const MenuItemStyle = styled(MenuItem)`
  // background: rgba(42, 42, 62, 1);
  background: white;
  align-items: start;
  &:hover {
    // background: rgba(105, 86, 186, 1);
    background: rgba(243, 243, 254, 1);
  }
`;

const ButtonMenu = styled(Button)`
  &:after {
    clear: both;
    display: block;
    content: '';
    background: url(${process.env.PUBLIC_URL}/images/hamburger.svg);
    background-repeat: no-repeat;
    width: 26px;
    height: 19px;
  }
  background-color: transparent;
  z-index: 1;
  border: none;
  margin-right: 20px;
  padding: 8px;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

const LinkStyle = styled(Link)`
  margin-right: 30px;
  color: #fff;
`;

const DropdownMenuStyle = styled(DropdownMenu)``;

const Div = styles.div`
  background: ${Localization.navbarBackgroundColor};
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
`;
const DropdownStyle = styled(Dropdown)`
  font-weight: 400;
  &:hover,
  &:focus,
  &:visited {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;
const DropdownToggleType = styled(DropdownToggle)`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

export default Header;
